article {
  min-height: calc(100vh - #{$header-height} - #{$footer-height});

  section {
    padding: 1rem 0;
    background: $section-background-color;

		&:nth-of-type(odd) {
			background: $section-background-color-alt;
		}
  }

  ul.project-list {
    list-style: none;
    padding-left: 0;

    > li {
      a.project-name {
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      ul, ol {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }

  ul.project-links {
    list-style: none;
    padding-left: 0;

    li {
      display:inline;
      margin-right: calc(5px/2);
      margin-left: calc(5px/2);

      a {
        font-size: 15px;
        font-weight: 400;
        line-height: 1em;
        padding-right: 10px;
      }

      &:not(:last-child):after {
        border-left-style: solid;
        border-left-width: 1px;
        content: "";
        border-color: #DDD;
      }
    }
  }

  .divider {
    padding: 50px 0;
    min-height: 50px;
    background-image: url(/images/Group-280.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    text-align: center;

    a {
      color: $divider-link-color;

      &:hover {
        color: $divider-link-hover-color;
      }
    }
  }
}
