header {
  height: $header-height;
  background: $header-background-color;

  position: -webkit-sticky;
  position: sticky;
  top: 0;

  .container {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
