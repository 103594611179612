$header-height: 96px;
$header-background-color: #151536;

$footer-height: 200px;
$footer-background-color: #F4F5F7;
$footer-link-color: #313131;
$footer-menu-height: 40px;
$footer-h2-color: #161344;

$section-background-color: $footer-background-color;
$section-background-color-alt: #FFFFFF;

$link-color: #0C58CA;
$link-hover-color: #3491FF;

$divider-link-hover-color: #FFB800;
$divider-link-color: #FFD400;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1170px
);

@import "bootstrap/bootstrap";

@import "header";
@import "article";
@import "footer";
