footer {
  min-height: $footer-height;
  background: $footer-background-color;

  .container {
    .row:first-child {
      min-height: calc(#{$footer-height} - #{$footer-menu-height});

      padding-top: 20px;

      .col {
        padding-left: 40px;
        padding-right: 40px;

        p {
          margin-bottom: 0;
          max-width: 350px;
          margin-left: auto;
          margin-right: auto;
        }

        img {
          max-width: 250px;
        }

        h2 {
          color: $footer-h2-color;
          font-size: 18px;
          font-weight: 300;
          text-transform: uppercase;
        }

        ul {
          list-style: none;
          padding-left: 0;
        }
      }
    }
    .row:last-child {
      height: $footer-menu-height;
    }
  }

  ul.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    list-style: none;
    padding-left: 0;

    li {
      display: inline;
      margin-right: calc(25px/2);
      margin-left: calc(25px/2);

      &:not(:last-child):after {
        border-left-style: solid;
        border-left-width: 1px;
        content: "";
        border-color: #DDD;
      }
    }
  }

  a {
    font-size: 15px;
    font-weight: 400;
    line-height: 1em;
    padding-right: 25px;
    text-decoration: none;
    color: $footer-link-color;

    &:hover {
      color: $link-hover-color;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  footer {
    .row-cols-1>* {
      text-align: center;

      .col {
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
  footer {
    .row-cols-md-3>* {
      text-align: left;
    }
  }
}
